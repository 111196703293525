import React, { useContext, useState, Suspense } from "react";
import { Route } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CircularProgress } from "@material-ui/core";
import { appStateController, APP_CONTROLLER_ACTIONS } from "../states/index.js";
import { appContext } from "../states/context/appContext";
import "./RoutesComponent.css";

const Overview = React.lazy(() => import("../components/Overview"));
const SidebarComponent = React.lazy(() =>
  import("../components/SidebarComponent")
);
const HeaderComponent = React.lazy(() =>
  import("../components/HeaderComponent")
);
const BankruptcyPrediction = React.lazy(() =>
  import("../components/BankruptyComponent")
);
const SupplierDelay = React.lazy(() =>
  import("../components/SupplierDelayComponent")
);
const ReportGenerator = React.lazy(() =>
  import("../components/ReportGeneratorComponent")
);
const PurchaseOrder = React.lazy(() =>
  import("../components/PurchaseOrderComponent")
);
const IntelliegencePage = React.lazy(() => import("./IntelligenceIndex"));
const MarketResearch = React.lazy(() =>
  import("./marketRearch/marketRearchIndex")
);
const MilestoneTrackerIndex = React.lazy(() =>
  import("./milestoneTracker/milestoneTrackerIndex")
);
const SrmIndex = React.lazy(() => import("./srm/srmIndex"));
const CommodityRisk = React.lazy(() =>
  import("../components/CommodityRiskComponent")
);
const DocumentPdfViewer = React.lazy(() =>
  import("../components/milestoneTracker/DocumentPdfViewer")
);
const UserGuide = React.lazy(() => import("../components/UserGuide"));
const ChoosePlan = React.lazy(() => import("../components/ChoosePlan"));
const CopilotComponent = React.lazy(() =>
  import("../components/kavidaCopilot/CopilotComponent")
);

const RoutesComponent = (props) => {
  const globalContext = useContext(appContext);
  const dispatchAction = useSetRecoilState(appStateController());
  const [routes, setRoutes] = useState([]);
  const [emailIntegration, setEmailIntegration] = useState({
    button: false,
    status: "connected",
  });

  React.useEffect(() => {
    (async () => {
      try {
        const { meta, data } = props.getTemplateApiData;
        if (meta?.message === "Success") {
          const { users } = data[0];
          globalContext.setExCountryLabel(users?.ex_country_label);
          globalContext.setExFactoryLabel(users?.ex_factory_label);
          dispatchAction({
            action: APP_CONTROLLER_ACTIONS.GET_USER,
            payload: users || {},
          });
          dispatchAction({
            action: APP_CONTROLLER_ACTIONS.UPDATE_MENU,
            payload: users.ui_template || {},
          });
          globalContext.setShowDeliverySlot(users.delivery_slot);
          globalContext.setShowDummyPo(users.show_dummy_po);
          const { email_integration_button, email_integration_status } = users;
          setEmailIntegration({
            button: email_integration_button,
            status: email_integration_status,
          });
          let uiTemplate = users.ui_template;
          let temp = {
            layout: "/kavida",
            component: "GettingStarted",
            icon: "",
            name: "Getting Started",
            path: "/getting-started",
            tabs: [],
          };
          let temp2 = {
            layout: "/kavida",
            component: "ChoosePlan",
            icon: "",
            name: "Choose the best plan for you",
            path: "/choose-plan",
            tabs: [],
          };
          let temp1 = [...uiTemplate];
          temp1.push(temp);
          temp1.push(temp2);
          setRoutes(temp1);
          globalContext.setSelectedMenu({
            name: users.ui_template[0].name,
          });
          return getRoutes();
        } else {
          setRoutes([]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const getRoutes = () => {
    if (
      window.location.pathname !== "/kavida/supplier-delays" &&
      window.location.pathname !== "/kavida/commodity-risks" &&
      !window.location.pathname.includes("/supplier")
    )
      return routes.map((prop, key) => {
        if (prop.layout === "/kavida") {
          if (prop.component === "Overview")
            return (
              <Route key={key} path={prop.layout + prop.path}>
                <Overview tabs={prop.tabs} />
              </Route>
            );
          if (prop.component === "Intelligence")
            return (
              <Route
                path={prop.layout + prop.path}
                component={IntelliegencePage}
                key={key}
              />
            );
          if (prop.component === "GettingStarted")
            return (
              <Route
                path={prop.layout + prop.path}
                component={UserGuide}
                key={key}
              />
            );
          if (prop.component === "ChoosePlan")
            return (
              <Route
                path={prop.layout + prop.path}
                component={ChoosePlan}
                key={key}
              />
            );
          if (prop.component === "MilestoneTracker")
            return window.location.pathname ===
              "/kavida/milestone-tracker/doc-viewer" ? (
              <Route
                path={prop.layout + prop.path}
                component={DocumentPdfViewer}
                key={key}
              />
            ) : (
              <Route
                path={prop.layout + prop.path}
                component={MilestoneTrackerIndex}
                key={key}
              />
            );
          if (prop.component === "MarketResearch")
            return (
              <Route
                path={prop.layout + prop.path}
                component={MarketResearch}
                key={key}
              />
            );
          if (prop.component === "SRM")
            return (
              <Route
                path={prop.layout + prop.path}
                component={SrmIndex}
                key={key}
              />
            );
          if (prop.component === "Copilot")
            return (
              <Route
                path={prop.layout + prop.path}
                component={CopilotComponent}
                key={key}
              />
            );
          if (prop.component === "ReportGenerator") {
            let path = `${prop.layout}${prop.path}`;
            return <Route path={path} component={ReportGenerator} key={key} />;
          }
          if (prop.component === "Bankruptcy")
            return (
              <Route key={key} path={prop.layout + prop.path}>
                <BankruptcyPrediction tabs={prop.tabs} />
              </Route>
            );
        } else {
          return null;
        }
      });
    else if (window.location.pathname === "/kavida/supplier-delays")
      return <Route component={SupplierDelay} />;
    else if (window.location.pathname === "/kavida/commodity-risks")
      return <Route component={CommodityRisk} />;
    else if (window.location.pathname.includes("/supplier")) {
      return <Route component={PurchaseOrder} />;
    }
  };

  const getHeaderTitle = (path, name) => {
    if (path === "/copilot" || path === "/command-tower") {
      return "";
    } else {
      return <h1 className="componentHeading">{name}</h1>;
    }
  };

  return (
    <div>
      <Suspense fallback={<></>}>
        <HeaderComponent emailIntegration={emailIntegration} />
      </Suspense>
      <div>
        <div>
          <Suspense fallback={<></>}>
            <SidebarComponent
              routes={routes}
              selectedMenu={globalContext.selectedMenu}
            />
          </Suspense>
        </div>
        <div
          className="routesContainer"
          style={
            window.location.pathname.includes("/kavida/copilot")
              ? { paddingLeft: "48px" }
              : {}
          }
        >
          {window.location.pathname !== "/kavida/getting-started" ? (
            routes.length &&
            routes.map((route) => {
              if (
                window.location.pathname.indexOf(route.layout + route.path) !==
                -1
              ) {
                return getHeaderTitle(route.path, route.name);
              }
            })
          ) : (
            <div className="user-guide-heading">
              <h1>Get to know Kavida</h1>
            </div>
          )}
          <Suspense
            fallback={
              <div className="fixed-loading-container">
                <CircularProgress />
              </div>
            }
          >
            {getRoutes(routes)}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default RoutesComponent;
