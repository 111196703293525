import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./OnboardingStyle.js";
import Logo from "../../assets/images/kavida-logo.svg";
import KavidaLogo from "../../assets/images/k-logo.svg";
import KavidaGreyLogo from "../../assets/images/kavida-grey-logo.svg";
import ErpLogo from "../../assets/images/integration-logo.svg";
import Erp from "../../assets/images/erp-logo.svg";
import Users from "../../assets/images/profile-user.svg";
import Message from "../../assets/images/message.svg";
import SupplierPortal from "../../assets/images/supplier-portal.svg";
import RightArrow from "../../assets/images/right-arrow.svg";
import PurchaseOrder from "../../assets/images/purchase-order-grp.png";
import ERP from "../../assets/images/ERP-grp.png";
import Communication from "../../assets/images/Communication-grp.png";
import SupplierPortalGrp from "../../assets/images/supplier-portal-grp.png";
import { UJFirstTimeLogin } from "../../services/api.service.js";
import { appContext } from "../../states/context/appContext";

export default function OnboardingComponent() {
  const styles = useStyles();
  const [menu, setMenu] = useState("kavida");
  const globalContext = useContext(appContext);

  const handleNavigation = () => {
    ujCheckFirstSession();
  };

  const ujCheckFirstSession = async () => {
    try {
      const firstTimeLoginData = {
        api_name: "uj_check_first_session",
        data: {},
      };
      await UJFirstTimeLogin(firstTimeLoginData);
      globalContext.setIsFirstTimeLogin(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleMenu = (menu) => {
    setMenu(menu);
  };
  const handleViewNext = (menu) => {
    window.scrollTo(0,0);
    setMenu(menu);
  };
  return (
    <>
      <div className={styles.headerContainer}>
        <img className={styles.logo} src={Logo} />
      </div>
      <div
        className="welcome-main-container"
        style={{
          height: menu == "supplierPortal" ? "900px" : "740px",
        }}
      >
        <div className={styles.menuContainer + " res-menu-container"}>
          <span
            onClick={() => handleMenu("kavida")}
            className={
              (menu == "kavida" ? styles.selectedMenu : styles.menu) +
              " menu-container"
            }
          >
            <img
              className={styles.kavidaImg + " menu-icon"}
              src={menu == "kavida" ? KavidaLogo : KavidaGreyLogo}
            />
            <span
              style={{ marginLeft: "-2px" }}
              className={
                (menu == "kavida" ? styles.selectedMenuText : styles.menuText) +
                " menu-caption kavida-menu-caption"
              }
            >
              KAVIDA
            </span>
          </span>
          <span
            onClick={() => handleMenu("erp")}
            className={
              (menu == "erp" ? styles.selectedMenu : styles.menu) +
              " menu-container"
            }
          >
            <img
              style={{ marginTop: "5px", height: "18px" }}
              className="menu-icon"
              src={menu == "erp" ? Erp : ErpLogo}
            />
            <span
              className={
                (menu == "erp" ? styles.selectedMenuText : styles.menuText) +
                " menu-caption"
              }
            >
              ERP INTEGRATION
            </span>
          </span>
          <span
            onClick={() => handleMenu("communication")}
            className={
              (menu == "communication" ? styles.selectedMenu : styles.menu) +
              " menu-container"
            }
          >
            <img
              style={{
                marginTop: "7px",
                height: "18px",
                filter:
                  menu == "communication"
                    ? "invert(49%) sepia(90%) saturate(4868%) hue-rotate(228deg) brightness(97%) contrast(92%)"
                    : "",
              }}
              className="menu-icon"
              src={Message}
            />
            <span
              className={
                (menu == "communication"
                  ? styles.selectedMenuText
                  : styles.menuText) + " menu-caption menu-caption-desktop"
              }
            >
              COMMUNICATION
            </span>
            <span
              className={
                (menu == "communication"
                  ? styles.selectedMenuText
                  : styles.menuText) + " menu-caption menu-caption-mobile"
              }
            >
              COMMUN<br/>-ICATION
            </span>
          </span>
          <span
            onClick={() => handleMenu("supplierPortal")}
            className={
              (menu == "supplierPortal" ? styles.selectedMenu : styles.menu) +
              " menu-container"
            }
          >
            <img
              style={{ marginTop: "6px", height: "18px" }}
              className="menu-icon"
              src={menu == "supplierPortal" ? SupplierPortal : Users}
            />
            <span
              className={
                (menu == "supplierPortal"
                  ? styles.selectedMenuText
                  : styles.menuText) + " menu-caption"
              }
            >
              SUPPLIER PORTAL
            </span>
          </span>
        </div>
        <div className="right-container">
          <div
            className={styles.descriptionContainer + " description-container"}
          >
            {menu == "kavida" ? (
              <span className={styles.heading + " tab-heading"}>
                Hi there, <br className="desktop-view" /> welcome to Kavida
              </span>
            ) : menu == "erp" ? (
              <span className={styles.heading + " tab-heading"}>
                Plug into your existing <br className="desktop-view" /> ERP
              </span>
            ) : menu == "communication" ? (
              <span className={styles.heading + " tab-heading"}>
                Streamline supplier <br className="desktop-view" /> communication
              </span>
            ) : menu == "supplierPortal" ? (
              <span className={styles.supplierPortalHeading + " tab-heading"}>
                Better collaboration with <br className="desktop-view" /> supplier portals
              </span>
            ) : null}
            {menu == "kavida" ? (
              <>
                <span className={styles.contentDescription + ' margin-top-2-percent'}>
                  Kavida helps procurement teams <br className="mobile-view" /> collaborate with
                  <br className="desktop-view" /> their suppliers, track order <br className="mobile-view" /> updates and detect delays{" "}
                  <br className="desktop-view" />
                  to delivery dates.
                </span>
                <span className={styles.contentDescription}>
                  Let’s spend a few minutes to walk through <br className="mobile-view" /> how you can <br className="desktop-view" /> use Kavida as your go-to order <br className="mobile-view" /> management platform.
                </span>
              </>
            ) : menu == "erp" ? (
              <>
                <span className={styles.contentDescription + ' margin-top-2-percent'}>
                  Easily connect Kavida with your current <br className="mobile-view" /> ERP. When you <br className="desktop-view" /> raise an
                  order, Kavida syncs <br className="mobile-view" /> the data, manages the order <br className="desktop-view" /> and sends all <br className="mobile-view" />
                  updates right to your ERP.
                </span>
                <span className={styles.contentDescription}>
                  Don’t have an ERP? Just upload a <br className="mobile-view" /> spreadsheet with your <br className="desktop-view" /> order
                  and supplier <br className="mobile-view" /> information - and we’ll handle the rest.
                </span>
              </>
            ) : menu == "communication" ? (
              <>
                <span className={styles.contentDescription + ' margin-top-2-percent'}>
                  Link your email so you can centralize all <br className="mobile-view" /> order  <br className="desktop-view" /> communications
                  in one platform.
                </span>
                <span className={styles.contentDescription}>
                  Automate supplier follow ups, missing <br className="mobile-view" /> document  <br className="desktop-view" /> requests and be aligned on your <br className="mobile-view" /> production schedules.
                </span>
              </>
            ) : menu == "supplierPortal" ? (
              <>
                <span className={styles.supplierContentDescription + ' margin-top-2-percent'}>
                  A dedicated portal to collaborate with every <br className="mobile-view" /> supplier,  <br className="desktop-view" />
                  ensuring you’re both on the same <br className="mobile-view" /> page with delivery  <br className="desktop-view" /> dates,
                  documents, and <br className="mobile-view" /> production timelines.
                </span>
                <span className={styles.supplierContentDescription}>
                  Combined with an order chat to <br className="mobile-view" /> communicate -  <br className="desktop-view" />with in-built
                  options that <br className="mobile-view" /> lets you request and review  <br className="desktop-view" /> delivery dates <br className="mobile-view" /> and
                  order documents.
                </span>
              </>
            ) : null}

            {menu == "supplierPortal" && (
              <Button
                onClick={() => handleNavigation()}
                variant="contained"
                className={styles.platformbtn + " platform-btn"}
              >
                <span style={{ fontFamily: "Figtree" }}>
                  {" "}
                  Done, take me to the platform{" "}
                </span>
                <i
                  style={{ color: "#fff", paddingLeft: "8px" }}
                  className="fa-regular fa-circle-check"
                ></i>
              </Button>
            )}
            <div>
              {menu == "kavida" ? (
                <div
                  className={styles.skipbtnContainer + " skip-btn-container"}
                >
                  <Button
                    onClick={() => handleViewNext("erp")}
                    variant="contained"
                    className={styles.viewNextBtn + " view-next-btn"}
                  >
                    <span style={{ fontFamily: "Figtree" }}>View Next </span>
                    <img className={styles.leftArrow} src={RightArrow} />
                  </Button>
                  <span
                    onClick={() => handleNavigation()}
                    className={styles.skipText + " skip-text"}
                  >
                    Skip
                  </span>
                </div>
              ) : menu == "erp" ? (
                <div
                  className={styles.skipbtnContainer + " skip-btn-container"}
                >
                  <Button
                    onClick={() => handleViewNext("communication")}
                    variant="contained"
                    className={styles.viewNextBtn + " view-next-btn"}
                  >
                    <span style={{ fontFamily: "Figtree" }}>View Next </span>
                    <img className={styles.leftArrow} src={RightArrow} />
                  </Button>
                  <span
                    onClick={() => handleNavigation()}
                    className={styles.skipText + " skip-text"}
                  >
                    Skip
                  </span>
                </div>
              ) : menu == "communication" ? (
                <div
                  className={styles.skipbtnContainer + " skip-btn-container"}
                >
                  <Button
                    onClick={() => handleViewNext("supplierPortal")}
                    variant="contained"
                    className={styles.viewNextBtn + " view-next-btn"}
                  >
                    <span style={{ fontFamily: "Figtree" }}>View Next </span>
                    <img className={styles.leftArrow} src={RightArrow} />
                  </Button>
                  <span
                    onClick={() => handleNavigation()}
                    className={styles.skipText + " skip-text"}
                  >
                    Skip
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="purchase-order-img"
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "-3%",
              marginTop: "3%",
              width: "46%",
            }}
          >
            <img
              src={
                menu == "kavida"
                  ? PurchaseOrder
                  : menu == "erp"
                  ? ERP
                  : menu == "communication"
                  ? Communication
                  : menu == "supplierPortal"
                  ? SupplierPortalGrp
                  : null
              }
            />
          </div>
        </div>
        <div className="warning-block">
          <i
            className="fa fa-exclamation-circle warning-fa-fa"
            aria-hidden="true"
          ></i>
          <span className="warning-caption">
            For a better user experience, we recommend using your desktop{" "}
          </span>
        </div>
      </div>
    </>
  );
}
