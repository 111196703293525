import React, { Component } from "react";
export const userJourneyContext = React.createContext();

class userJourneyContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      app_tour_state: { index: 0, type: "" },
      selectedMenu: {},
      setAppTourState: this.setAppTourState,
    };
  }

  setAppTourState = (app_tour_state) => {
    this.setState({ app_tour_state });
  };

  setSelectedMenu = (selectedMenu) => {
    this.setState({ selectedMenu });
  };

  render() {
    return (
      <userJourneyContext.Provider value={this.state}>
        {this.state.children}
      </userJourneyContext.Provider>
    );
  }
}

export default userJourneyContextProvider;
