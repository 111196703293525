import Cookies from "js-cookie";

const AUTH_COOKIE_NAME = "wsed";
export const setAuthCookie = (val) => {
  if (val) Cookies.remove(AUTH_COOKIE_NAME, val);
  else Cookies.remove(AUTH_COOKIE_NAME);
};

export const getAuthCookie = () => {
  return Cookies.get(AUTH_COOKIE_NAME);
};

export const delAuthCookie = (val) => {
  if (val) Cookies.remove(AUTH_COOKIE_NAME);
};
