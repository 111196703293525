import { makeStyles } from "@material-ui/core/styles";
import "./Onboarding.css";
const styles = makeStyles((theme) => ({
  headerContainer: {
    height: "50px",
    background: "#FFFFFF 0% 0% no-repeat",
    border: "2px solid #F2F2F4",
    textAlign: "center",
  },
  logo: {
    paddingTop: "10px",
  },
  heading: {
    fontSize: "40px",
    lineHeight: "55px",
    fontWeight: 600,
    marginTop: "11%",
    fontFamily: "Sora",
  },
  supplierPortalHeading: {
    fontSize: "40px",
    lineHeight: "55px",
    fontWeight: 600,
    marginTop: "11%",
    fontFamily: "Sora",
  },
  menuContainer: {
    marginLeft: "4%",
    flexDirection: "column",
    paddingTop: "3%",
    justifyContent: "space-between",
  },
  selectedMenu: {
    background: "#FFFFFF",
    width: "90%",
    padding: "8px",
    borderRadius: "8px",
    boxShadow: "0px 3px 32px #00000014",
    border: "2px solid #E5E5E9",
    borderRadius: "8px",
    display: "flex",
    marginTop: "10%",
    cursor: "pointer",
  },
  kavidaImg: {
    marginTop: "6px",
    height: "18px",
  },
  selectedMenuText: {
    color: "#6361EE",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Sora",
    padding: "7px",
  },
  menuText: {
    color: "#7F7D94",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Sora",
    padding: "7px",
  },
  menu: {
    background: "#FAFAFA",
    boxShadow: "inset 0px 3px 8px #00000008, 0px 3px 24px #00000000",
    border: "2px solid #E5E5E9",
    borderRadius: "8px",
    width: "90%",
    padding: "8px",
    display: "flex",
    marginTop: "10%",
    cursor: "pointer",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1%",
    marginLeft: "7%",
    width: "44%",
  },
  contentDescription: {
    color: "#7F7D94",
    fontSize: "19px",
    marginTop: "8%",
    fontFamily: "Figtree",
    lineHeight: 1.5,
  },
  supplierContentDescription: {
    color: "#7F7D94",
    fontSize: "19px",
    marginTop: "8%",
    fontFamily: "Figtree",
    lineHeight: 1.5,
  },
  viewNextBtn: {
    background: "transparent linear-gradient(314deg, #9F88FF 0%, #1999A3 100%)",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "14px",
    textTransform: "none",
  },
  platformbtn: {
    background: "transparent linear-gradient(314deg, #9F88FF 0%, #1999A3 100%)",
    borderRadius: "8px",
    color: "#fff",
    width: "51%",
    marginTop: "9%",
    fontSize: "14px",
    textTransform: "none",
  },
  leftArrow: {
    height: "16px",
    paddingLeft: "5px",
  },
  PurchaseOrderContainer: {
    background: "#FFFFFF",
    width: "120%",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0px 3px 32px #00000014",
    borderRadius: "8px",
    marginTop: "10%",
    marginRight: "3%",
    height: "27%",
  },
  purchaseOrderText: {
    fontWeight: 600,
  },
  requireContainer: {
    background: "#ffefef",
    padding: "10px",
    height: "auto",
  },
  requireText: {
    color: "#ff3e3e",
    fontWeight: 600,
  },
  awaitingContainer: {
    background: "#fffaeb",
    padding: "10px",
    height: "auto",
  },
  awaitingText: {
    color: "#dcb638",
    fontWeight: 600,
  },
  onTrackContainer: {
    background: "#edf7f8",
    padding: "10px",
    height: "auto",
    width: "27%",
  },
  onTrackText: {
    color: "#259ea8",
    fontWeight: 600,
  },
  skipText: {
    color: "#6361EE",
    marginTop: "2%",
    marginLeft: "4%",
    cursor: "pointer",
    fontFamily: "Figtree",
    fontSize: "14px",
  },
  skipbtnContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "12%",
  },
}));

export default styles;
