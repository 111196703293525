import Keycloak from "keycloak-js";

// import { kyecloak_config } from "../public/keycloak"

export const keycloak = new Keycloak(
    {
        "realm": process.env.REACT_APP_KEYCLOAK_REALM,
        "url": process.env.REACT_APP_KEYCLOAK_URL,
        "ssl-required": "none",
        "clientId": process.env.REACT_APP_KEYCLOAK_CLIENTID,
        "resource": process.env.REACT_APP_KEYCLOAK_CLIENTID,
        "public-client": true,
        "verify-token-audience": true,
        "use-resource-role-mappings": true,
        "confidential-port": 0
    } 
    );

// export const keycloak = Keycloak("/keycloak.json");

