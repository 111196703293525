import "./App.css";
import "react-date-range/dist/styles.css"; // style for react-date-range
import "react-date-range/dist/theme/default.css"; // style for react-date-range
import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { keycloak } from "./constants";
import {
  UJValidateRegistrationForm,
  getSidebarMenu,
  onBoardingRequest,
} from "./services/api.service";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@material-ui/core";
import "../node_modules/flag-icons/css/flag-icons.min.css";
import * as amplitude from "@amplitude/analytics-browser";
import mixpanel from "mixpanel-browser";
import { appContext } from "./states/context/appContext";
import OnboardingComponent from "./components/onboarding/OnboardingComponent";
import { ToastContainer } from "react-toastify";
import RoutesComponent from "./pages/RoutesComponent";

const chmln = require("@chamaeleonidae/chmln");

mixpanel.init(process.env.REACT_APP_MIX_PANEL_API_KEY, {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

const App = () => {
  const [login, setLogin] = useState({
    keycloak: null,
    authenticated: false,
  });
  const globalContext = useContext(appContext);
  const [loading, setLoading] = useState(true);
  const [getTemplateApiData, setGetTemplateApiData] = useState({
    meta: "",
    data: "",
  });

  let urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("at");
  let refreshToken = urlParams.get("rt");
  const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

  useEffect(() => {
    if (!window.location.pathname.includes("/forms")) {
      if (token && refreshToken) {
        localStorage.setItem("at", token);
        localStorage.setItem("rt", refreshToken);
      } else if (localStorage.getItem("at") && localStorage.getItem("rt")) {
        token = localStorage.getItem("at");
        refreshToken = localStorage.getItem("rt");
      }
      keycloak
        .init({
          onLoad: "login-required",
          checkLoginIframe: false,
          token,
          refreshToken,
        })
        .then((authenticated) => {
          setLogin({
            keycloak: keycloak,
            authenticated: authenticated,
          });
          if (authenticated) {
            try {
              window.accessToken = keycloak.token;
              copilotAgentRegisterNewUser();
            } catch (e) {
              setLoading(false);
              console.log(e);
            }
          }
        });
    }
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const payLoad = {
        api_name: "getTemplate",
      };
      const { meta, data } = await getSidebarMenu(payLoad);
      if (meta.status !== 200) {
        setLoading(false);
        return;
      }
      globalContext.setUserRole(data[0].users.role);
      setGetTemplateApiData({
        meta: meta,
        data: data,
      });
      const email = data[0].users.email;
      const name = data[0].users.name;
      setLoading(false);
      globalContext.setUserEmail(data[0].users.email);
      if (data[0].users.first_time_login) {
        globalContext.setIsFirstTimeLogin(true);
      }
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "owpechn6",
        custom_launcher_selector: "#custom_link",
        name: data[0].users.name && data[0].users.name,
        email: data[0].users.email && data[0].users.email,
      });
      window.Intercom("update");

      chmln.init(process.env.REACT_APP_CHAMELEON_API_KEY, {
        fastUrl: "https://fast.chameleon.io/",
      });
      chmln.identify(keycloak.token, {
        email: email,
        name: name,
      });
      amplitude.init(AMPLITUDE_API_KEY, email, {
        defaultTracking: true,
      });
      mixpanel.identify(email);
      mixpanel.track("Sign IN", {
        "Signup Type": process.env.REACT_APP_ENVIRONMENT,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const copilotAgentRegisterNewUser = async () => {
    try {
      setLoading(true);
      const { name, email } = keycloak.tokenParsed;
      const payLoad = {
        api_name: "copilot_agent_register_new_user",
        data: {
          user_name: name,
          user_email: email,
          user_phone: "",
          company_name: "",
          company_id: keycloak.tokenParsed["Company-Id"],
          token: keycloak.token,
        },
      };
      const { meta, data } = await UJValidateRegistrationForm(payLoad);
      if (meta.message === "Success") {
        const {
          redirect_url,
          new_token,
          agent_first_time_session,
          trigger_po_onboarding,
        } = data;

        // set the session storage to true to detect the first time user
        if (agent_first_time_session) {
          localStorage.setItem("agent_first_time_login", "true");
        }
        if (redirect_url) {
          //storing the trigger_po_onboarding because the onboarding will work with redirect url and second time redirect url will be empty
          document.cookie = `trigger_po_onboarding=${
            trigger_po_onboarding ? "true" : ""
          }; path=/;`;
          localStorage.setItem(
            "trigger_po_onboarding",
            trigger_po_onboarding ? "true" : ""
          );
          // Fallback to redirecting in the same tab
          window.location.replace(redirect_url);
        } else {
          //get the trigger_po_onboarding from the cookies also
          const trigger_po_onboardingCookie = getCookie(
            "trigger_po_onboarding"
          );
          // to check if any
          if (
            (localStorage.getItem("trigger_po_onboarding") ||
              trigger_po_onboardingCookie) &&
            localStorage.getItem("agent_first_time_login")
          ) {
            settingTheNewToken(new_token);
            triggerPOOnboarding();
            globalContext.setIsAgentFirstTimeLogin(true);
            globalContext.setIsCopilotOnboarding(true);
            localStorage.removeItem("trigger_po_onboarding");
            document.cookie =
              "trigger_po_onboarding=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            localStorage.removeItem("agent_first_time_login");
          } else {
            if (
              agent_first_time_session ||
              localStorage.getItem("agent_first_time_login")
            ) {
              settingTheNewToken(new_token);
            } else {
              //removing the local storage and cookies
              localStorage.removeItem("trigger_po_onboarding");
              localStorage.removeItem("agent_first_time_login");
              document.cookie =
                "trigger_po_onboarding=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            getData();
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const settingTheNewToken = (new_token) => {
    if (new_token) {
      globalContext.setCopilotNewToken(new_token);
      keycloak.token = new_token;
    }
    localStorage.removeItem("agent_first_time_login");
  };

  function getCookie(name) {
    let cookieArray = document.cookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      // Check if this cookie string begins with the name we want
      if (cookie.indexOf(name + "=") === 0) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  const triggerPOOnboarding = async () => {
    try {
      setLoading(true);
      const payLoad = {
        api_name: "trigger_po_onboarding",
        data: {
          token: keycloak.token,
        },
      };
      const { meta, data } = await onBoardingRequest(payLoad);
      if (meta.message === "Success") {
        getData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      {login.keycloak && login.authenticated && !loading ? (
        !globalContext.isFirstTimeLogin ? (
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                render={(props) => (
                  <RoutesComponent
                    keycloak={login.keycloak}
                    {...props}
                    getTemplateApiData={getTemplateApiData}
                  />
                )}
              />
            </Switch>
          </BrowserRouter>
        ) : (
          <OnboardingComponent />
        )
      ) : (
        <div className="fixed-loading-container">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default App;
