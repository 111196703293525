import React, { Component } from "react";
export const appContext = React.createContext();

class appContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      notificationMenu: null,
      po_id: null,
      supplier_id: null,
      po_line_id: null,
      user_journey: false,
      mile_stone_routing: "default",
      app_tour_state: { index: 0 },
      app_tour_modal: false,
      app_tour: false,
      selectedMenu: {},
      history: {},
      demoAccount: false,
      show_dummy_po: false,
      updateDummyChecklist: false,
      alertData: null,
      changeAlertStatus: false,
      isRefresh: false,
      emailToast: null,
      notificationBanner: null,
      notifications: [],
      timeStamp: 0,
      refreshFinder: "",
      isClosedPo: false,
      tourData: null,
      isReset: false,
      userEmail: "",
      isFirstTimeLogin: false,
      incoterm: false,
      isDisableEditSplit: false,
      exCountryLabel: "",
      exFactoryLabel: "",
      showDeliverySlot: false,
      refreshIsArchive: false,
      copilotNewThread: false,
      showCopilotSaveModal: false,
      isAgentFirstTimeLogin: false,
      copilotUrlParams: "",
      userRole: "",
      copilotNewToken: "",
      isCopilotOnboarding: false,
      setRefreshIsArchive: this.setRefreshIsArchive,
      setIncoterm: this.setIncoterm,
      setUserJourney: this.setUserJourney,
      setSupplierId: this.setSupplierId,
      setPurchaseOrderId: this.setPurchaseOrderId,
      setPurchaseOrderLineId: this.setPurchaseOrderLineId,
      setAppTourModal: this.setAppTourModal,
      setAppTour: this.setAppTour,
      setAppTourState: this.setAppTourState,
      setNotificationMenu: this.setNotificationMenu,
      setSelectedMenu: this.setSelectedMenu,
      setHistory: this.setHistory,
      setDemoAccount: this.setDemoAccount,
      setShowDummyPo: this.setShowDummyPo,
      setUpdateDummyChecklist: this.setUpdateDummyChecklist,
      setEmailToast: this.setEmailToast,
      setNotificationBanner: this.setNotificationBanner,
      setNotifications: this.setNotifications,
      setTimeStamp: this.setTimeStamp,
      setAlertData: this.setAlertData,
      setChangeAlertStatus: this.setChangeAlertStatus,
      setIsRefresh: this.setIsRefresh,
      setRefreshFinder: this.setRefreshFinder,
      setIsClosedPo: this.setIsClosedPo,
      setTourData: this.setTourData,
      setIsReset: this.setIsReset,
      setUserEmail: this.setUserEmail,
      setIsFirstTimeLogin: this.setIsFirstTimeLogin,
      setIsDisableEditSplit: this.setIsDisableEditSplit,
      setShowDeliverySlot: this.setShowDeliverySlot,
      setExCountryLabel: this.setExCountryLabel,
      setExFactoryLabel: this.setExFactoryLabel,
      setCopilotNewThread: this.setCopilotNewThread,
      setShowCopilotSaveModal: this.setShowCopilotSaveModal,
      setIsAgentFirstTimeLogin: this.setIsAgentFirstTimeLogin,
      setCopilotUrlParams: this.setCopilotUrlParams,
      setUserRole: this.setUserRole,
      setCopilotNewToken: this.setCopilotNewToken,
      setIsCopilotOnboarding: this.setIsCopilotOnboarding
    };
  }

  setSupplierId = (supplier_id) => {
    this.setState({ supplier_id });
  };

  setPurchaseOrderId = (po_id) => {
    this.setState({ po_id });
  };

  setPurchaseOrderLineId = (po_line_id) => {
    this.setState({ po_line_id });
  };

  setAppTourModal = (app_tour_modal) => {
    this.setState({ app_tour_modal });
  };

  setAppTour = (app_tour) => {
    this.setState({ app_tour });
  };

  setAppTourState = (app_tour_state) => {
    this.setState({ app_tour_state });
  };

  setNotificationMenu = (notificationMenu) => {
    this.setState({ notificationMenu });
  };
  setNotifications = (notifications) => {
    this.setState({ notifications });
  };

  setSelectedMenu = (selectedMenu) => {
    this.setState({ selectedMenu });
  };

  setHistory = (history) => {
    this.setState({ history });
  };

  setUserJourney = (user_journey) => {
    this.setState({ user_journey });
  };

  setDemoAccount = (demoAccount) => {
    this.setState({ demoAccount });
  };

  setShowDummyPo = (show_dummy_po) => {
    this.setState({ show_dummy_po });
  };

  setUpdateDummyChecklist = (flag) => {
    this.setState({ flag });
  };

  setEmailToast = (emailToast) => {
    this.setState({ emailToast });
  };

  setNotificationBanner = (notificationBanner) => {
    this.setState({ notificationBanner });
  };

  setTimeStamp = (timeStamp) => {
    this.setState({ timeStamp });
  };

  setAlertData = (alertData) => {
    this.setState({ alertData });
  };

  setChangeAlertStatus = (changeAlertStatus) => {
    this.setState({ changeAlertStatus });
  };

  setChangeAlertStatus = (changeAlertStatus) => {
    this.setState({ changeAlertStatus });
  };

  setIsRefresh = (isRefresh) => {
    this.setState({ isRefresh });
  };

  setRefreshFinder = (refreshFinder) => {
    this.setState({ refreshFinder });
  };

  setIsClosedPo = (isClosedPo) => {
    this.setState({ isClosedPo });
  };

  setTourData = (tourData) => {
    this.setState({ tourData });
  };

  setIsReset = (isReset) => {
    this.setState({ isReset });
  };

  setUserEmail = (userEmail) => {
    this.setState({ userEmail });
  };

  setIsFirstTimeLogin = (isFirstTimeLogin) => {
    this.setState({ isFirstTimeLogin });
  };

  setIncoterm = (incoterm) => {
    this.setState({ incoterm });
  };

  setIsDisableEditSplit = (isDisableEditSplit) => {
    this.setState({ isDisableEditSplit });
  };

  setShowDeliverySlot = (showDeliverySlot) => {
    this.setState({ showDeliverySlot });
  };

  setRefreshIsArchive = (refreshIsArchive) => {
    this.setState({ refreshIsArchive });
  };

  setExCountryLabel = (exCountryLabel) => {
    this.setState({ exCountryLabel });
  };

  setExFactoryLabel = (exFactoryLabel) => {
    this.setState({ exFactoryLabel });
  };

  setCopilotNewThread = (copilotNewThread) => {
    this.setState({ copilotNewThread });
  };
  
  setShowCopilotSaveModal = (showCopilotSaveModal) => {
    this.setState({ showCopilotSaveModal });
  };
  setIsAgentFirstTimeLogin = (isAgentFirstTimeLogin) => {
    this.setState({ isAgentFirstTimeLogin });
  };
  setCopilotUrlParams = (copilotUrlParams) => {
    this.setState({ copilotUrlParams });
  };
  setUserRole = (userRole) => {
    this.setState({ userRole });
  };
  setCopilotNewToken = (copilotNewToken) => {
    this.setState({ copilotNewToken });
  };
  setIsCopilotOnboarding = (isCopilotOnboarding) => {
    this.setState({ isCopilotOnboarding });
  };
  render() {
    return (
      <appContext.Provider value={this.state}>
        {this.state.children}
      </appContext.Provider>
    );
  }
}

export default appContextProvider;
