import fetch from "unfetch";
import { merge } from "lodash";
import { getAuthCookie } from "../utils/cookie";
import { keycloak } from "../constants";

// const host = "https://b2b1o9u7t3.execute-api.us-east-2.amazonaws.com";
// const host = "http://api-kavida.us-east-2.elasticbeanstalk.com/dev";
// const host =
//   "http://api-kavida.us-east-2.elasticbeanstalk.com/dev";

// const host = "https://dev-api.kavida.ai/dev";

const host = process.env.REACT_APP_API_URL;

// const host = "https://api.kavida.ai/prod";

// const host = "http://keycloakauthrouter-env.eba-fckiithn.us-east-2.elasticbeanstalk.com/uat";

export const SERVER_METHODS = { GET: "GET", POST: "POST" };
export const requestServer = async (method, path, payload) => {
  keycloak
    .updateToken(5)
    .then(function () {
      console.log("token is refreshed");
    })
    .catch(function () {
      // keycloak.logout();
      console.log("Failed to refresh token");
    });
  let options = { headers: { esid: getAuthCookie() } };
  if (method === SERVER_METHODS.POST) {
    options = merge({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token,
      },
      body: JSON.stringify(payload),
      mode: "no-cors",
    });
  } else {
    options = merge({
      method: SERVER_METHODS.GET,
      headers: {
        Authorization: "Bearer " + keycloak.token,
      },
    });
  }
  let res = await fetch(host + path, options);
  return res;
};
