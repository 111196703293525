import { SERVER_METHODS, requestServer } from "./request";

const path = "/genericapi";

export const getSidebarMenu = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const getGlobalNews = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSuppliersAffected = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMetrics = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSuppliers = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const suppliersDisruption = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const suppliersDelayOverview = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const commodityList = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendUpdateRequest = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const milestoneTracker = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const formUpdates = async (payload) => {
  let path = "/public/genericapi";
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const mileStoneComplete = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendMessageSupCommn = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const retriveMessageSupCommn = async (payload) => {
  try {
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const UJValidateRegistrationForm = async (payload) => {
  try {
    let path = "/public/genericapi";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const UJRegisterNewCompany = async (payload) => {
  try {
    let path = "/auth";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const UJAvailableLocation = async (payload) => {
  try {
    let path = "/auth";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const GenerateDummyPo = async (payload) => {
  try {
    let path = "/genericapi";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const UJFirstTimeLogin = async (payload) => {
  try {
    let path = "/genericapi";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const onBoardingRequest = async (payload) => {
  try {
    let path = "/onboarding";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
};

export const addEditContact = async (payload) => {
  try {
    let path = "/genericapi";
    let res = await requestServer(SERVER_METHODS.POST, path, payload);
    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(res.status, await res.json());
    }
  } catch (error) {
    console.log(error);
  }
}
