import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./new_index.css";
import "@fontsource/inter";
import App from "./App";
import { RecoilRoot } from "recoil";
import AppContext from "./states/context/appContext";
import UserJourneyContext from "./states/context/userJourneyContext";

ReactDOM.render(
  <RecoilRoot>
    <AppContext>
      <UserJourneyContext>
        <App />
      </UserJourneyContext>
    </AppContext>
  </RecoilRoot>,
  document.getElementById("root")
);
