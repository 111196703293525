import { atom, selectorFamily } from "recoil";
// import * as _ from "lodash";

export const appMenu = atom({
  key: "appMenu",
  default: [],
});

export const appUser = atom({
  key: "appUser",
  default: {},
});

export const appFilterDelay = atom({
  key: "appFilterDelay",
  default: "all",
});

export const appCategoryTabs = atom({
  key: "appCategoryTabs",
  default: [],
});

export const appGetDocument = atom({
  key: "appGetDocument",
  default: [],
});

export const appUpdatePO = atom({
  key: "appUpdatePO",
  default: false,
});

export const appGetPoList = atom({
  key: "appGetPoList",
  default: [],
});
export const appGetAlertInfo = atom({
  key: "appGetAlertInfo",
  default: {},
});
export const appShowBadge = atom({
  key: "appShowBadge",
  default: {},
});
export const appPoLine = atom({
  key: "appPoLine",
  default: "",
});
export const appEditPo = atom({
  key: "appEditPo",
  default: false,
});
export const appAddSupplier = atom({
  key: "appAddSupplier",
  default: false,
});

export const appPoId = atom({
  key: "appPoId",
  default: {},
});

export const appLogin = atom({
  key: "appLogin",
  default: false,
});

export const appChangeIncoterm = atom({
  key: "appChangeIncoterm",
  default: null,
});

export const appRequestUpdate = atom({
  key: "appRequestUpdate",
  default: null,
});

export const appDocumentUpload = atom({
  key: "appDocumentUpload",
  default: null,
});

export const APP_CONTROLLER_ACTIONS = {
  UPDATE_MENU: "UPDATE_MENU",
  GET_USER: "GET_USER",
  CATEGORY_TABS: "CATEGORY_TABS",
  FILTER_DELAY: "FILTER_DELAY",
  GET_DOCUMENT: "GET_DOCUMENT",
  UPDATE_PO: "UPDATE_PO",
  GET_PO_LIST: "GET_PO_LIST",
  GET_ALERT_INFO: "GET_ALERT_INFO",
  SHOW_BADGE: "SHOW_BADGE",
  PO_LINE: "PO_LINE",
  EDIT_PO: "EDIT_PO",
  ADD_SUPPLIER: "ADD_SUPPLIER",
  PO_ID: "PO_ID",
  LOGIN: "LOGIN",
  CHANGE_INCOTERM: "CHANGE_INCOTERM",
  REQUEST_UPDATE: "REQUEST_UPDATE",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
};

export const appStateController = selectorFamily({
  key: "appStateController",
  set:
    () =>
    ({ get, set }, { action, payload }) => {
      switch (action) {
        case APP_CONTROLLER_ACTIONS.UPDATE_MENU:
          set(appMenu, payload);
          break;
        case APP_CONTROLLER_ACTIONS.GET_USER:
          set(appUser, payload);
          break;
        case APP_CONTROLLER_ACTIONS.CATEGORY_TABS:
          set(appCategoryTabs, payload);
          break;
        case APP_CONTROLLER_ACTIONS.FILTER_DELAY:
          set(appFilterDelay, payload);
          break;
        case APP_CONTROLLER_ACTIONS.GET_DOCUMENT:
          set(appGetDocument, payload);
          break;
        case APP_CONTROLLER_ACTIONS.UPDATE_PO:
          set(appUpdatePO, payload);
        case APP_CONTROLLER_ACTIONS.GET_PO_LIST:
          set(appGetPoList, payload);
        case APP_CONTROLLER_ACTIONS.GET_ALERT_INFO:
          set(appGetAlertInfo, payload);
        case APP_CONTROLLER_ACTIONS.SHOW_BADGE:
          set(appShowBadge, payload);
        case APP_CONTROLLER_ACTIONS.PO_LINE:
          set(appPoLine, payload);
        case APP_CONTROLLER_ACTIONS.EDIT_PO:
          set(appEditPo, payload);
        case APP_CONTROLLER_ACTIONS.ADD_SUPPLIER:
          set(appAddSupplier, payload);
        case APP_CONTROLLER_ACTIONS.PO_ID:
          set(appPoId, payload);
        case APP_CONTROLLER_ACTIONS.LOGIN:
          set(appLogin, payload);
        case APP_CONTROLLER_ACTIONS.CHANGE_INCOTERM:
          set(appChangeIncoterm, payload);
        case APP_CONTROLLER_ACTIONS.REQUEST_UPDATE:
          set(appRequestUpdate, payload);
        case APP_CONTROLLER_ACTIONS.DOCUMENT_UPLOAD:
          set(appDocumentUpload, payload);
        default:
          break;
      }
    },
});
